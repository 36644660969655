
if ($('.js-map').length) {

    $('.js-map').each(function(){
        var self = $(this),
            centerCoords = [55.803712, 37.754056],
            placemarkCoords = [55.804122, 37.755697],

        init = function init() {
            var myMap = new ymaps.Map(self.prop('id'), {
                center: centerCoords,
                zoom: 17,
                controls: []
            }),

            myPlacemark = new ymaps.Placemark(placemarkCoords, {}, {
                iconLayout: 'default#image',
                iconImageHref: 'images/pin.svg',
                iconImageSize: [32, 49],
                iconImageOffset: [-16, -49]
            }),

            // Создадим пользовательский макет ползунка масштаба.
            ZoomLayout = ymaps.templateLayoutFactory.createClass('', {

                // Переопределяем методы макета, чтобы выполнять дополнительные действия
                // при построении и очистке макета.
                build: function () {
                    // Вызываем родительский метод build.
                    ZoomLayout.superclass.build.call(this);

                    // Привязываем функции-обработчики к контексту и сохраняем ссылки
                    // на них, чтобы потом отписаться от событий.
                    this.zoomInCallback = ymaps.util.bind(this.zoomIn, this);
                    this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this);

                    // Начинаем слушать клики на кнопках макета.
                    $('#zoom-in').bind('click', this.zoomInCallback);
                    $('#zoom-out').bind('click', this.zoomOutCallback);
                },

                clear: function () {
                    // Снимаем обработчики кликов.
                    $('#zoom-in').unbind('click', this.zoomInCallback);
                    $('#zoom-out').unbind('click', this.zoomOutCallback);

                    // Вызываем родительский метод clear.
                    ZoomLayout.superclass.clear.call(this);
                },

                zoomIn: function () {
                    var map = this.getData().control.getMap();
                    map.setZoom(map.getZoom() + 1, {checkZoomRange: true});
                },

                zoomOut: function () {
                    var map = this.getData().control.getMap();
                    map.setZoom(map.getZoom() - 1, {checkZoomRange: true});
                },
            }),

            zoomControl = new ymaps.control.ZoomControl({options: {layout: ZoomLayout}});

            myMap.controls.add(zoomControl);
            myMap.geoObjects.add(myPlacemark);
            myMap.behaviors.disable('scrollZoom');

            // Отключаем событие 'drag' на touch устройствах
            self.on('touchstart', function () {
              myMap.behaviors.disable('drag');
            });
            self.on('touchend', function () {
              myMap.behaviors.enable('drag');
            })
        };

        ymaps.ready(init);
    });
}
